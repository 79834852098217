.photo {
  width: 20rem;
  height: 30rem;
  border: 6px solid #fff;
  object-fit: contain;
}


/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 1500px) {
    .photo {
        width: 15rem;
        height: 22rem;
      }
    }

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 720px) {
    .photo {
        width: 20rem;
        height: 30rem;
      }
  }