.app__container {
  text-align: center;
  display: grid;
  grid-template-columns: 40% 40%;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.app__left_side {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  padding-left: 5rem;
}

.app__center_side {
  padding-top: 25rem;
  margin: auto;
  width: 50%;
}

.app__right_side {
  padding-top: 25rem;
}

.app__buttons_photo_wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
}

/* ================ MEDIA QUERIES (125% size on laptop) ================== */

@media screen and (max-width: 1830px) {
  .app__container {
    grid-template-columns: 50% 50%;
  }

}

/* ================ MEDIA QUERIES (MEDIUM AND SMALL DEVICES) ================== */

@media screen and (max-width: 1500px) {

  .app__container {
    grid-template-columns: 3fr;
    grid-template-rows: 1fr;
    justify-content: center;
    width: 100%;
  }

  .app__buttons_photo_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 90%;
  }

  .app__left_side {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding-left: 0rem;
  }

  .app__center_side {
    padding-top: 1rem;
  }

  .app__right_side {
    padding-top: 1rem;
  }
}

/* ================ TABLET IN HORIZONTAL VIEW ================== */

@media screen and (max-width: 1200px) {
  .app__buttons_photo_wrapper {
    width: 90%;
    padding-left: 0rem;
  }

}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================== */

@media screen and (max-width: 720px) {
  .app__container {
    grid-template-columns: 1fr;
    grid-template-rows: 3fr;
  }

  .app__buttons_photo_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    padding-left: 0rem;
  }
}
