.header__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  padding-top: 10rem;
}

.logo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 3px solid #fff;
}

.title {
  padding-left: 4rem;
  font-size: 52px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-family: "Josefin Sans", sans-serif;
  font-weight: normal;
  color: rgb(86, 86, 91);
}

.subtitle {
  padding-left: 4rem;
  padding-top: 0;
  margin-top: 0;
  font-size: 28px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: normal;
  color: rgb(86, 86, 91);
}

/* ================ TABLET IN HORIZONTAL VIEW ================== */

@media screen and (max-width: 1200px) {
  .header__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    padding-top: 0rem;
  }

  .logo {
    width: 180px;
    height: 180px;
    margin-left: 4rem;
  }

  .title {
    padding-left: 2rem;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 9vw;
  }

  .subtitle {
    padding-left: 2rem;
    padding-top: 0;
    margin-top: 0;
    font-size: 4.75vw;
  }

}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 1024px) {

  .header__wrapper {
    padding-top: 1rem;
  }

  .logo {
    margin-left: 1rem;
  }

}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================== */

@media screen and (max-width: 720px) {
  .header__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
    grid-column-gap: 0px;
    grid-row-gap: 5px;
    padding-top: 3rem;
  }

  .logo {
    width: 300px;
    height: 300px;
    margin-left: 0rem;
  }

  .title {
    padding-left: 0rem;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 12vw;
  }

  .subtitle {
    padding-left: 0rem;
    padding-top: 0;
    margin-top: 0;
    font-size: 6.5vw;
  }
}
