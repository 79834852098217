.buttons__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.buttons__wrapper a {
  margin: 10px;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid white;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 250px;
  color: inherit;
  text-decoration: none;
  font-family: "Josefin Sans", sans-serif;
  font-weight: normal;
  color: rgb(86, 86, 91);
}

.buttons__wrapper a:after {
  content: "";
  background-color: rgba(166, 173, 154, 0.9);
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.buttons__wrapper a:hover:after {
  top: 0px;
  left: 0px;
}
