.description__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  text-align: justify;
}

.description__wrapper p {
  text-align: justify;
  font-family: "Josefin Sans", sans-serif;
  font-weight: light;
  color: rgb(86, 86, 91);
  font-size: 20px;
}

.description__contact_details {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  text-align: justify;
}

.description__contact_details p {
  font-family: "Josefin Sans", sans-serif;
  color: rgb(86, 86, 91);
  font-weight: bold;
  font-size: 20px;
  margin: 0px;
}

.description__contact_details a {
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
  color: rgb(86, 86, 91);
  font-weight: bold;
  font-size: 20px;
  margin: 0px;
  text-decoration: none;
}

.description__contact_details a:hover
{
  cursor: pointer;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 1500px) {
  .description__wrapper {
    width: 90%;
    padding-left: 2.5rem;
  }

  .description__contact_details {
    width: 80%;
    padding-left: 2.5rem;
  }

  .description__contact_details p {
    font-size: 18px;
    margin: 0px;
  }

}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================== */

@media screen and (max-width: 720px) {
  .description__wrapper {
    width: 80%;
    padding-left: 2.5rem;
  }

  .description__contact_details {
    width: 80%;
    padding-left: 2.5rem;
  }

  .description__contact_details p {
    font-size: 18px;
    margin: 0px;
  }

}
